import {PatientAdditionalNameSimpleSerializer, PatientInsuranceSerializer, PayerAltNameDetailSerializer} from '../@core/api.service';
import {InsuranceEligibility} from './insurance-eligibility';

export class PatientInsurance implements PatientInsuranceSerializer {
    biller_record_identifier: string;
    direct_bill: boolean;
    id: number;
    last_mco_eligibility_request: InsuranceEligibility;
    last_medicare_eligibility_request: InsuranceEligibility;
    mco_additional_name: PatientAdditionalNameSimpleSerializer;
    mco_company: string;
    mco_company_payer_alt_name: PayerAltNameDetailSerializer;
    mco_effective_date: Date | string;
    mco_group: string;
    mco_policy: string;
    mco_priority: string;
    medicaid_additional_name: PatientAdditionalNameSimpleSerializer;
    medicaid_company_name: string;
    medicaid_policy: string;
    medicaid_priority: string;
    medicare_additional_name: PatientAdditionalNameSimpleSerializer;
    medicare_beneficiary_id: string;
    medicare_coverage: string;
    medicare_priority: string;

    private _empty: boolean;

    get empty() {
        return this._empty;
    }

    constructor(x: PatientInsuranceSerializer) {
        const {last_mco_eligibility_request, last_medicare_eligibility_request, ...y} = x;
        Object.assign(this, {
            ...y,
            last_mco_eligibility_request: new InsuranceEligibility(last_mco_eligibility_request),
            last_medicare_eligibility_request: new InsuranceEligibility(last_medicare_eligibility_request),
        });
        this._empty = Object.entries(y).every(([key, val]) => !val || key === 'id');
    }
}
